declare global {
  interface Window {
    dataLayer: Event[];
    Flourish: {
      loadEmbed: Function;
    };
  }
}

type Event = {
  event: string;
  host: string;
  [key: string]: any;
};

/**
 * Is supplied with a safe window object
 */
type EventCreator = (callbackParams: { window: Window }) => Event;

export function track(createEvent: EventCreator) {
  if (typeof window == "undefined") {
    return;
  }
  window.dataLayer = window.dataLayer ?? [];
  const event = createEvent(window);
  window.dataLayer.push(event);
}

type PageViewParams =
  | {
      category:
        | "Home"
        | "What we do"
        | "Who we are"
        | "Insights"
        | "Experts"
        | "Contact"
        | "unknown";
    }
  | {
      category: "Topic_listing";
      topic: string;
    }
  | {
      category: "Article";
      topic: string;
      author: string | string[];
      publishDate: string;
      articleTitle: string;
    }
  | {
      category: "Search";
      searchTerm: string;
    }
  | {
      category: "Expert";
      name: string;
    };

export const pageView =
  (params: PageViewParams): EventCreator =>
  ({ window }) => {
    return {
      host: window.location.host,
      event: "tbi_content",
      tbi_content_category: params.category,
      tbi_page_title: window.document.title,
      tbi_page_path: window.location.pathname,
      // @ts-ignore These only exist for certain categories.
      // We don't care if they are undefined
      tbi_topic: params.topic,
      // @ts-ignore
      tbi_author: params.author,
      // @ts-ignore
      tbi_publish_date: params.publishDate,
      // @ts-ignore
      tbi_search_term: params.searchTerm,
      // @ts-ignore
      tbi_article_title: params.articleTitle,
    };
  };
